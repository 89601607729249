import { defineStore } from "pinia";

import { httpService } from "../services/http";
import { router } from "../router";
import jwt_decode from "jwt-decode";

const baseUrl = `${import.meta.env.VITE_API_URL}/api/v1/auth`;

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem("user")),
    returnUrl: null,
  }),
  actions: {
    async login(email, password) {
      this.loading = true;
      let authData;
      try {
        authData = await httpService.post(`${baseUrl}/login`, {
          email,
          password,
        });
      } catch {
        authData = { error: "wrong username or pass" };
      }
      if (authData.error) {
        this.loading = false;
        return authData;
      }
      // temporarily set the token
      this.user = authData;

      // Get all user data
      let data = await httpService.get(`${baseUrl}/users`);
      if (data.error) {
        this.loading = false;
        return data;
      }

      let user = data.user;
      user.access_token = authData.access_token;
      this.user = user;

      localStorage.setItem("user", JSON.stringify(user));
      this.loading = false;
      router.push(this.returnUrl || "/");
    },
    async register(email, password) {
      this.loading = true;
      const data = await httpService.post(`${baseUrl}/users`, {
        email,
        password,
      });
      if (data.error) {
        this.loading = false;
        return data;
      }
      return await this.login(email, password);
    },
    async password_reset(email) {
      this.loading = true;
      const data = await httpService.post(`${baseUrl}/password-reset`, {
        email,
      });
      this.loading = false;
      if (data.error) {
        return data;
      }
    },
    async password_set(password, token) {
      this.loading = true;
      const data = await httpService.post(`${baseUrl}/password-set`, {
        token,
        password,
      });
      this.loading = false;
      if (data.error) {
        return data;
      }
    },
    token_expired() {
      if (this.user) {
        const data = jwt_decode(this.user.access_token);
        if (data.exp < Date.now() / 1000) {
          localStorage.removeItem("user");
          this.user = null;
          return true;
        }
        return false;
      }
      return true;
    },
    logout() {
      this.user = null;
      localStorage.removeItem("user");
      router.push("/login");
    },
  },
});
