<script setup>
import { h } from "vue";
import { RouterLink } from "vue-router";
import { NLayoutSider, NIcon, NMenu } from "naive-ui";
import {
  Home,
  Logout,
  TagGroup,
  CollapseCategories,
  Api,
  Debug,
  Webhook,
  Report,
  Settings,
} from "@vicons/carbon";

function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) });
}
const menuOptions = [
  {
    label: () =>
      h(
        RouterLink,
        {
          to: { name: "workspace" },
        },
        { default: () => "Workspace" }
      ),
    key: "workspace",
    icon: renderIcon(Home),
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: { name: "reports" },
        },
        { default: () => "Reports" }
      ),
    key: "reports",
    icon: renderIcon(Report),
  },
  {
    key: "divider-1",
    type: "divider",
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: { name: "settings" },
        },
        { default: () => "Settings" }
      ),
    key: "settings",
    icon: renderIcon(Settings),
  },
  {
    key: "divider-2",
    type: "divider",
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: { name: "api" },
        },
        { default: () => "API" }
      ),
    key: "api",
    icon: renderIcon(Api),
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: { name: "" },
        },
        { default: () => "Webhooks" }
      ),
    key: "webhooks",
    disabled: true,
    icon: renderIcon(Webhook),
  },
  {
    key: "divider-3",
    type: "divider",
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: { name: "report-bug" },
        },
        { default: () => "Report a bug" }
      ),
    key: "report-bug",
    icon: renderIcon(Debug),
  },
  {
    key: "divider-4",
    type: "divider",
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: { name: "logout" },
        },
        { default: () => "Logout" }
      ),
    key: "logout",
    icon: renderIcon(Logout),
  },
];
</script>

<template>
  <n-layout-sider
    bordered
    collapse-mode="width"
    :collapsed-width="64"
    :width="240"
    show-trigger
    class="s-side-menu"
  >
    <n-menu
      :collapsed-width="64"
      :collapsed-icon-size="24"
      :icon-size="24"
      :options="menuOptions"
      :value="$route.name"
    />
  </n-layout-sider>
</template>

<style scoped></style>
