<script setup>
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
import javascript from "highlight.js/lib/languages/javascript";
import { RouterView } from "vue-router";
import { darkTheme, NLayout, NMessageProvider } from "naive-ui";
import HeaderNav from "./components/layout/HeaderNav.vue";
import SideMenu from "./components/layout/SideMenu.vue";
import FooterNav from "./components/layout/FooterNav.vue";
import { useAuthStore } from "./stores/auth";

const theme = darkTheme;
const auth = useAuthStore();
const themeOverrides = {
  common: {
    // https://www.naiveui.com/en-US/os-theme/docs/customize-theme#Theme-editor
    // primaryColor: "#00AEFF",
  },
};
hljs.registerLanguage("json", json);
hljs.registerLanguage("javascript", javascript);
</script>

<template>
  <n-config-provider
    :theme="theme"
    :theme-overrides="themeOverrides"
    :hljs="hljs"
  >
    <n-layout position="absolute">
      <HeaderNav />
      <n-layout
        position="absolute"
        :has-sider="!!auth.user"
        style="top: 64px; bottom: 64px"
      >
        <SideMenu v-if="!!auth.user" />
        <n-layout content-style="padding: 24px;">
          <n-message-provider>
            <RouterView :key="$route.fullPath" />
          </n-message-provider>
        </n-layout>
      </n-layout>
      <FooterNav />
    </n-layout>
  </n-config-provider>
</template>

<style>
.n-list__header:has(.empty) {
  padding: 0;
}
</style>
