import { createRouter, createWebHistory } from "vue-router";
import NotFoundView from "./views/NotFoundView.vue";

import { useAuthStore } from "./stores/auth";

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("./views/LoginView.vue"),
    },
    {
      path: "/password-reset",
      name: "password-reset",
      component: () => import("./views/PasswordResetView.vue"),
    },
    {
      path: "/password-set",
      name: "password-set",
      component: () => import("./views/PasswordSetView.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("./views/RegisterView.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: () => {
        const auth = useAuthStore();
        auth.logout();
      },
    },
    {
      path: "/workspace",
      name: "workspace",
      component: () => import("./views/WorkspaceView.vue"),
    },
    {
      path: "/reports",
      name: "reports",
      component: () => import("./views/ReportsView.vue"),
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("./views/SettingsView.vue"),
    },
    {
      path: "/api",
      name: "api",
      component: () => import("./views/APIView.vue"),
    },
    {
      path: "/report-bug",
      name: "report-bug",
      component: () => import("./views/ReportBugView.vue"),
    },
    {
      // For now just redirect, we might have something later
      path: "/",
      redirect: { name: "workspace" },
    },

    { path: "/:pathMatch(.*)*", component: NotFoundView },
  ],
});

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    "/login",
    "/register",
    "/password-reset",
    "/password-set",
  ];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && (!auth.user || auth.token_expired())) {
    auth.returnUrl = to.fullPath;
    return "/login";
  } else if (!authRequired && auth.user) {
    return "/workspace";
  }
});
